import { api } from "./api";

const URLS = {
    libraries: "libraries",
    library: "library",
    editLibrary: "admin/library",
};

export const getAllLibraries = () => {
    return api.get(URLS.libraries);
};

export const getLibrary = (payload) => {
    return api.get(`${URLS.library}/${payload.id}`);
};

export const editLibrary = (payload) => {
    return api.post(`${URLS.editLibrary}/${payload.id}`, payload.data);
};
