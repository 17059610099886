<template>
    <transition v-if="isInfoPage" name="fade-transform" mode="out-in">
        <router-view></router-view>
    </transition>
    <v-row v-else class="mt-2">
        <v-col cols="12">
            <v-row>
                <v-col>
                    <h2>{{ $t(`library.title`) }}</h2>
                </v-col>
            </v-row>
            <v-row v-if="libraryStatus_Success">
                <v-col
                    v-for="(project, index) in library"
                    :key="index"
                    cols="12"
                    md="3"
                >
                    <project-info-card
                        :project="project"
                        type="library"
                        card-width="372"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="372"
                    >
                        <template #viewAction>
                            <view-project-btn
                                @view-project="viewProject(project)"
                            ></view-project-btn>
                        </template>
                    </project-info-card>
                </v-col>
                <v-col v-if="library.length <= 0" cols="12" class="mt-4">
                    {{ $t("library.noData") }}
                </v-col>
            </v-row>
            <loading-spinner :show="libraryStatus_Pending"></loading-spinner>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { getAllLibraries } from "@/api/libraryApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
export default {
    name: "Library",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        LoadingSpinner,
    },

    data() {
        return {
            library: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            libraryStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["libraryStatus"]),
        isInfoPage() {
            if (this.$route.params.id) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async handleLibrary() {
            this.libraryStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getAllLibraries);

            if (error) {
                this.libraryStatus = apiStatus.Error;
                return;
            }

            this.library = response.data.data.filter(
                (item) => item.status == "publish"
            );
            this.libraryStatus = apiStatus.Success;
        },
        viewProject(project) {
            this.$router.push({
                name: "LibraryInfo",
                params: { id: project.id },
            });
        },
    },

    created() {
        this.handleLibrary();
    },
};
</script>

<style lang="scss" scoped></style>
