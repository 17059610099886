<template>
    <v-row>
        <v-col v-if="show" cols="12" class="text-center loading__container">
            <v-progress-circular
                :size="50"
                :color="color"
                indeterminate
            ></v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
};
</script>

<style scoped>
.loading__container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
