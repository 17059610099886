<template>
    <v-btn icon plain small @click="emitAction()">
        <v-icon size="18" color="text lighten-1">
            fa-solid fa-eye
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "ViewProjectBtn",

    methods: {
        emitAction() {
            this.$emit("view-project");
        },
    },
};
</script>
